import { deserializeError } from '@owl-frontend/api-client';
import { ASYNC_TIMEOUT_MS } from '@owl-lib/util';

const toJson = async (res: Response) => {
  const text = await res.text();
  return text.length ? JSON.parse(text) : {};
};

export function deserialize(res: Response): Promise<any> {
  return res.ok
    ? toJson(res)
    : toJson(res).then(async (data) => {
        throw deserializeError(res, data);
      });
}

const constructPayloadChecksum = async (data: {
  username: string;
  payload: string;
}): Promise<string> => {
  const { username, payload } = data;
  const encoder = new TextEncoder();
  const encoded = encoder.encode(`${username}${payload}`);
  const hashBuffer = await crypto.subtle.digest('SHA-256', encoded);
  return Array.from(new Uint8Array(hashBuffer))
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');
};

const getCookieValue = (cookieName: string): string | null => {
  const cookies = document.cookie;
  for (const cookie of cookies) {
    const [name, value] = cookie.split('=');
    if (name === cookieName) {
      return value;
    }
  }
  return null;
};

export const fetchWrapper = async (
  href: string,
  options: RequestInit,
  timeoutLimit = ASYNC_TIMEOUT_MS
): Promise<Response> => {
  const controller = new AbortController();
  let timeout: ReturnType<typeof setTimeout> | null = null;

  if (timeoutLimit > 0) {
    timeout = setTimeout(() => controller.abort(), timeoutLimit);
  }

  const response = await fetch(href, {
    ...options,
    // FIXME: webpack dev server not injecting token
    headers: {
      ...options.headers,
      'id-token': getCookieValue('id_token') ?? '',
    },
    signal: controller.signal,
  });

  if (timeout) {
    clearTimeout(timeout);
  }

  const checksum = response.headers?.get('checksum');

  if (checksum) {
    const username = response.headers?.get('username');

    if (!username) {
      throw new Error('Unable to verify response');
    }

    const payload = await deserialize(response.clone());
    const payloadChecksum = await constructPayloadChecksum({
      username,
      payload: JSON.stringify(payload),
    });

    if (payloadChecksum !== checksum) {
      throw new Error(`Checksums do not match! ${href}`);
    }
  }

  return response;
};
