// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".components-MainContainer-__layout--Uv7\\+I {\n  background: white;\n}\n\n.components-MainContainer-__header--f1pL3 {\n  display: flex;\n  align-items: center;\n  padding: 0 1rem;\n  background: none;\n}\n\n.components-MainContainer-__owlIcon--68liw {\n  width: 64px;\n  height: 64px;\n  background: none;\n}\n.components-MainContainer-__owlIcon--68liw.components-MainContainer-__owlIcon--68liw {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.components-MainContainer-__owlIcon--68liw > svg {\n  width: 24px;\n  height: 24px;\n  overflow: visible;\n}\n.components-MainContainer-__owlIcon--68liw > svg > * {\n  stroke: #c5c5c5;\n  fill: #c5c5c5;\n}\n\n.components-MainContainer-__menu--XZQgY {\n  display: flex;\n  border-bottom: none;\n  justify-content: center;\n  flex: 1;\n  min-width: 0;\n}", "",{"version":3,"sources":["webpack://./src/components/MainContainer/MainContainer.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EAME,WAAA;EACA,YAAA;EACA,gBAAA;AAJF;AAHE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAKJ;AACE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AACJ;AACI;EACE,eAAA;EACA,aAAA;AACN;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,OAAA;EACA,YAAA;AADF","sourcesContent":[".layout {\n  background: white;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  padding: 0 1rem;\n  background: none;\n}\n\n.owlIcon {\n  &#{&} {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  width: 64px;\n  height: 64px;\n  background: none;\n\n  & > svg {\n    width: 24px;\n    height: 24px;\n    overflow: visible;\n\n    & > * {\n      stroke: #c5c5c5;\n      fill: #c5c5c5;\n    }\n  }\n}\n\n.menu {\n  display: flex;\n  border-bottom: none;\n  justify-content: center;\n  flex: 1;\n  min-width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": "components-MainContainer-__layout--Uv7+I",
	"header": "components-MainContainer-__header--f1pL3",
	"owlIcon": "components-MainContainer-__owlIcon--68liw",
	"menu": "components-MainContainer-__menu--XZQgY"
};
export default ___CSS_LOADER_EXPORT___;
