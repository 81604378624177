import {
  TypedUseSelectorHook,
  useSelector as useSelectorRedux,
} from 'react-redux';
import { OwlStore } from '@owl-frontend/redux';
import sessionSlice from '../data/session/logic';
import localizationSlice from './localization/logic';
import storeOptions from './storeOptions';

const store = new OwlStore([], {}, storeOptions);
store.addSlice(localizationSlice);
store.addSlice(sessionSlice);
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./localization/logic', () =>
    store.addSlice(localizationSlice, true)
  );
  module.hot.accept('../data/session/logic', () =>
    store.addSlice(sessionSlice, true)
  );
}
export interface StoreState {
  [localizationSlice.name]: (typeof localizationSlice)['initialState'];
  [sessionSlice.name]: (typeof sessionSlice)['initialState'];
}
export default store as OwlStore<any> as OwlStore<StoreState>;
export const useSelector: TypedUseSelectorHook<StoreState> = useSelectorRedux;
