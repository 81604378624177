import React from 'react';
import { render } from 'react-dom';
import AppProvider from './context/AppProvider';
import ReduxProvider from './context/ReduxProvider';
import store from './data/store';
import './styles/index.scss';

const root = document.getElementById('owl-frontend-app-platform-root');

render(
  <React.StrictMode>
    <ReduxProvider reduxStore={store.reduxStore}>
      <AppProvider />
    </ReduxProvider>
  </React.StrictMode>,
  root
);
