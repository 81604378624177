import React from 'react';
import Icon, {
  HomeOutlined,
  LogoutOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
import { Avatar, Dropdown, Layout, Menu, Modal } from 'antd';
import OwlLogo from '../../assets/logo-26.svg';
import { actions as sessionSliceActions } from '../../data/session/logic';
import { generatePath, RouteDefs } from '../Routing/Routing';
import styles from './MainContainer.module.scss';

const MainContainer = () => {
  const dispatch = useDispatch();
  // const {
  //   actions: { current: currentAction, requestToken: requestAction },
  // } = useSelector((s: StoreState) => s['session']);

  const dropdownItems = React.useMemo(() => {
    return [
      {
        key: 'logout',
        icon: <LogoutOutlined />,
        label: (
          <Link
            data-testid="logout-btn"
            to="#"
            onClick={() => {
              Modal.confirm({
                content: 'You sure you are signing out?',
                onOk: () => {
                  dispatch(sessionSliceActions.requestLogout());
                },
              });
            }}
          >
            Logout
          </Link>
        ),
      },
    ];
  }, []);

  return (
    <Layout className={styles.layout}>
      <Layout.Header className={styles.header}>
        <Icon className={styles.owlIcon} component={OwlLogo} />
        <Menu
          className={styles.menu}
          selectedKeys={[location.pathname.split('/')[1]]}
          mode="horizontal"
          items={[
            {
              key: 'claims',
              icon: <HomeOutlined />,
              label: <Link to={generatePath(RouteDefs.Claims)}>Claims</Link>,
            },
            {
              key: 'admin',
              icon: <UserSwitchOutlined />,
              label: <Link to={generatePath(RouteDefs.Admin)}>Admin</Link>,
            },
          ]}
          selectable
        />
        <Dropdown
          placement="topRight"
          menu={{ selectable: false, items: dropdownItems }}
        >
          <Avatar icon={<UserOutlined />} />
        </Dropdown>
      </Layout.Header>
      <Layout>
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default MainContainer;
