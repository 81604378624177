import { call, delay, race, type SagaGenerator } from 'typed-redux-saga';
import {
  AsyncActionState,
  asyncActionStateMatchers,
  invoke,
  setupSlice,
} from '@owl-frontend/redux';
import sessionApiClient from '../../api/session/client';
import type { User } from '../../api/session/interface';

function* current(): SagaGenerator<User | undefined> {
  const { sessionCurrent, sessionTimeout } = yield* race({
    sessionCurrent: call(sessionApiClient.current),
    sessionTimeout: delay(10_000), // 10 seconds
  });

  if (sessionTimeout) {
    throw new Error('TIMED_OUT');
  }

  return sessionCurrent;
}

const POLL_INTERVAL_MS = 5 * 60_000; // 5 minutes
function* pollRefreshToken(): SagaGenerator<void> {
  while (true) {
    yield* call(sessionApiClient.requestNewToken, {});
    yield* delay(POLL_INTERVAL_MS);
  }
}

export interface SessionState {
  current?: User;
  actions: {
    current: AsyncActionState;
    pollRefreshToken: AsyncActionState;
    requestLogin: AsyncActionState<{ url: string; message: string }>;
    requestToken: AsyncActionState;
    requestLogout: AsyncActionState;
  };
}

const initialState: SessionState = {
  actions: {
    current: { status: 'uninitialized' },
    pollRefreshToken: { status: 'uninitialized' },
    requestLogin: { status: 'uninitialized' },
    requestToken: { status: 'uninitialized' },
    requestLogout: { status: 'uninitialized' },
  },
};

const slice = setupSlice('session', initialState)
  .addAsyncSagas({
    current,
    pollRefreshToken,
    requestLogin: invoke(sessionApiClient.requestLogin),
    requestToken: invoke(sessionApiClient.requestNewToken),
    requestLogout: invoke(sessionApiClient.requestLogout),
  })
  .addReducers({
    'current/fulfilled': (state, action) => {
      state.current = action.payload;
    },
    'requestLogin/fulfilled': (_, action) => {
      window.location.replace(action.payload.url);
    },
    'requestToken/fulfilled': () => {
      window.location.replace(window.location.origin);
    },
    'requestLogout/fulfilled': (_, action) => {
      window.location.replace(action.payload.url);
    },
  });

export const actions = slice.actions;
export default slice.addExtra(asyncActionStateMatchers(actions).all());
