import React, { createContext } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { useEffectWithPrev } from '@owl-frontend/components';
import Routing from '../components/Routing/Routing';
import { actions as localizationSliceActions } from '../data/localization/logic';
import { actions as sessionSliceActions } from '../data/session/logic';
import type { StoreState } from '../data/store';
interface Props extends React.PropsWithChildren {
  Router?: React.ComponentType;
}

export const Locale = createContext({
  locale: 'en-ca',
});

export const SessionContext = React.createContext<
  Omit<StoreState['session'], 'actions'>
>({});

const ContextProvider: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { Router = BrowserRouter } = props;
  const { locale } = useSelector((s: StoreState) => s['localization']);
  const {
    current,
    actions: { current: currentAction },
  } = useSelector((s: StoreState) => s['session']);
  const getCookieValue = (cookieName: string) => {
    return (
      document.cookie
        .split(';')
        .map((cookie) => cookie.split('=').map((part) => part.trim()))
        .find(([cookieKey]) => cookieKey === cookieName)?.[1] || null
    );
  };

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const authCode = urlParams.get('code');
    const state = urlParams.get('state');

    if (authCode && state) {
      dispatch(sessionSliceActions.requestToken({ authCode, state }));
    } else {
      dispatch(sessionSliceActions.current());
    }
    dispatch(localizationSliceActions.fetchMessages({ locale }));
  }, [locale]);

  useEffectWithPrev(
    (prevStatus) => {
      if (prevStatus !== 'pending') {
        return;
      }

      if (currentAction.status === 'rejected') {
        dispatch(
          sessionSliceActions.requestLogin({
            tenantId: getCookieValue('tenant') ?? '',
          })
        );
      }
    },
    [currentAction.status]
  );

  return (
    <Router>
      <IntlProvider
        locale={locale}
        defaultLocale="en"
        onError={() => {
          /// noop
        }}
      >
        <Locale.Provider
          value={{
            locale,
          }}
        >
          <SessionContext.Provider value={{ current }}>
            <Routing />
          </SessionContext.Provider>
        </Locale.Provider>
      </IntlProvider>
    </Router>
  );
};
export default ContextProvider;
